.site-header {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  display: none;
}

@media (max-width: 1100px) {

  .site-header {
    display: block;
  }
}

.site-header a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
  text-decoration: none;
  color: #000;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.site-header .site-header__company {
  letter-spacing: 0.1rem;
}

.site-header .site-header__site-name {
  margin: 0;
  font-weight: 600;
  font-size: 1.25rem;
  letter-spacing: 0.45rem;
}

.grommet .site-header a:not(.grommetux-anchor):not(.grommetux-button):hover {
  color: #fff;
}

:root {

  /* V2 variables */
}
.message {
  display: none;
  padding: 12px 0;
  border-radius: 4px;
  color: #000;
  font-size: 18px;
  text-align: center;
}
.show {
  display: block;
}
.success {
  color: #fff;
}

:root {

  /* V2 variables */
}
@font-face {
  font-family: Metric;
  src: url(https://hpefonts.s3.amazonaws.com/web/MetricHPE-Web-Regular.woff) format('woff');
}
@font-face {
  font-family: Metric;
  src: url(https://hpefonts.s3.amazonaws.com/web/MetricHPE-Web-Bold.woff) format('woff');
  font-weight: 700;
}
@font-face {
  font-family: Metric;
  src: url(https://hpefonts.s3.amazonaws.com/web/MetricHPE-Web-Semibold.woff) format('woff');
  font-weight: 600;
}
@font-face {
  font-family: Metric;
  src: url(https://hpefonts.s3.amazonaws.com/web/MetricHPE-Web-Light.woff) format('woff');
  font-weight: 100;
}
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
body {
  margin: 0;
  font-family: Metric, Helvetica, Arial, sans-serif;
  color: #4a4a4a;
  background-color: #f8f8f8 !important;
  overflow-x: hidden
}
body #ladybug {
  display: none;
  visibility: hidden;
}
.app {
  height: 100%;
  font-weight: normal;
  font-smoothing: antialiased;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
h2 {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 30px;
          margin-block-end: 30px;
}
h3 {
  -webkit-margin-before: 0;
          margin-block-start: 0;
}
p {
  -webkit-margin-before: 0;
          margin-block-start: 0;
}
/* Global styles */
:global {
}
.newsletter-popup {
  position: absolute;
  bottom: 0;
  right: 0;
  -webkit-transform: scale(0.65, 0.65);
  transform: scale(0.65, 0.65);
  -webkit-animation: popup 0.35s ease-out;
          animation: popup 0.35s ease-out;
  z-index: 2;
}
.newsletter-popup__close-button {
  font-size: 1.5rem;
  float: right;
  z-index: 10;
  margin-top: -35px;
  margin-right: 5px;
  z-index: 2;
}

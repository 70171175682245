.investments-container {
  padding-left: 96px;
}

@media (max-width: 1100px) {

  .investments-container {
    padding-left: 0px;
  }
}

.investments {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 24px;
  -ms-flex-flow: wrap;
      flex-flow: wrap;
  padding-top: 288px;
}

@media (max-width: 1100px) {

  .investments {
    padding-top: 18px;
    padding-left: 18px;
  }
}

@media (max-width: 500px) {

  .investments {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

:root {

  /* V2 variables */
}
:root {

  .card--article {
  background-color: #fff;
  border-radius: 24px;
  padding: 0;
  margin: 0;
  text-decoration: none;
  width: 272px;
  max-width: 272px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;

    .section-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 24px;
  gap: 10px;

  width: 100%;
  height: 192px;
    }

    .section-bottom {
  width: 100%;
  padding: 24px 24px 7px 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 12px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
    }

    .section-bottom-name {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  color: #777;
    }
  }

  .card--article img {
    -ms-flex-item-align: center;
        align-self: center;
    width: 100%;
    gap: 10px;
  }

  .card--article time {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #bbb;
  }

  .card--article p {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    margin: 0;
    color: #757575;
  }

  .card--article a {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-decoration: none;
    color: #444;
  }

  .card--article small {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #01a982;
    display: inline-block;
    padding: 24px;
    width: 100%;
  }
}
@media (max-width: 500px) {
  :root {
    .card--article {
      position: relative !important;
      top: auto !important;
      left: auto !important;
      -ms-flex-item-align: center;
          align-self: center;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
      width: 100%;
    }
  }
}
:root {
  .card--article {
    .section-bottom {
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
    }
  }
}
@media (max-width: 500px) {
  :root {
    .card--article {
      margin: 0 0 8% 0;
    }
  }
}
:root {
  .card--company {
  background-color: #fff;
  border-radius: 24px;
  padding: 0;
  margin: 0;
  text-decoration: none;
  width: 272px;
  max-width: 272px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;

    .section-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 24px;
  gap: 10px;

  width: 100%;
  height: 192px;
    }

    .section-bottom {
  width: 100%;
  padding: 24px 24px 7px 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 12px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
    }

    .section-bottom-name {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  color: #777;
    }
  }
  .card--company img {
    -ms-flex-item-align: center;
        align-self: center;
    width: 100%;
    gap: 10px;
  }
  .card--company time {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #bbb;
  }
  .card--company p {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    margin: 0;
    color: #757575;
  }
  .card--company a {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-decoration: none;
    color: #444;
  }
  .card--company small {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #01a982;
    display: inline-block;
    padding: 24px;
    width: 100%;
  }
}
@media (max-width: 500px) {
  :root {
    .card--company {
      position: relative !important;
      top: auto !important;
      left: auto !important;
      -ms-flex-item-align: center;
          align-self: center;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
      width: 100%;
    }
  }
}
:root {
  .card--company {
    .section-bottom {
      padding: 24px;
    }
  }
  .card--company p {
    color: #bbb;
  }
  .card--portfolio-article {
  background-color: #fff;
  border-radius: 24px;
  padding: 0;
  margin: 0;
  text-decoration: none;
  width: 272px;
  max-width: 272px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;

    .section-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 24px;
  gap: 10px;

  width: 100%;
  height: 192px;
    }

    .section-bottom {
  width: 100%;
  padding: 24px 24px 7px 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 12px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
    }

    .section-bottom-name {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  color: #777;
    }
  }
  .card--portfolio-article img {
    -ms-flex-item-align: center;
        align-self: center;
    width: 100%;
    gap: 10px;
  }
  .card--portfolio-article time {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #bbb;
  }
  .card--portfolio-article p {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    margin: 0;
    color: #757575;
  }
  .card--portfolio-article a {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-decoration: none;
    color: #444;
  }
  .card--portfolio-article small {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #01a982;
    display: inline-block;
    padding: 24px;
    width: 100%;
  }
}
@media (max-width: 500px) {
  :root {
    .card--portfolio-article {
      position: relative !important;
      top: auto !important;
      left: auto !important;
      -ms-flex-item-align: center;
          align-self: center;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
      width: 100%;
    }
  }
}
:root {
  .card--portfolio--history {
    background-color: #fff;
    border-radius: 24px;
    padding: 24px 48px;
    text-decoration: none;
    width: 168px;
    max-width: 168px;
    height: 144px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1 0 168px;
            flex: 1 0 168px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .card--portfolio--history span {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }
  .card--portfolio--history small {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    font-weight: 400;
  }
  .card--portfolio--team {
    background-color: #fff;
    color: #444;
    border-radius: 24px;
    padding: 24px 48px;
    text-decoration: none;
    width: 483px;
    max-width: 483px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 24px;
  }
  .card--portfolio--team div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .card--portfolio--team img {
    width: 96px;
    border-radius: 100px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .card--portfolio--team span {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }
  .card--portfolio--team small {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    font-weight: 400;
  }
}
@media (max-width: 700px) {
  :root {
    .card--portfolio--team {
      width: 360px;
      max-width: 360px;
    }
  }
}
:root {
  .card--play--video {
    background-color: #fff;
    opacity: 70%;
    -webkit-backdrop-filter: blur(24px);
            backdrop-filter: blur(24px);
    color: #444;
    border-radius: 24px;
    padding: 36px 72px;
    margin: 24px;
    text-decoration: none;
    width: 61.11%;
    max-width: 61.11%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 36px;
    cursor: pointer;
  }
  .card--play--video div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
@media (max-width: 1280px) {
  :root {
    .card--play--video div {
      display: none;
    }
  }
}
:root {
  .card--play--video img {
    width: 72px;
    height: 72px;
    border-radius: 200px;
  }
  .card--play--video span {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    font-weight: 800;
    line-height: 28px;
  }
  .card--play--video small {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  }
}
@media (max-width: 1280px) {
  :root {
    .card--play--video {
      background-color: transparent;
      opacity: 100%;
      -webkit-backdrop-filter: none;
              backdrop-filter: none;
      max-width: -webkit-fit-content;
      max-width: -moz-fit-content;
      max-width: fit-content;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin: 0px;
      padding: 0px;
      border: none;
    }
  }
}
.cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  margin-top: 100px;
  gap: 40px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

:root {

  /* V2 variables */
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2
}

.menu a {
  color: #fff;
  text-decoration: none;
  display: block;
  font-size: 32px;
  font-weight: 200;
  margin: 0 0 40px;
}

.menu button {
  color: #fff;
  text-decoration: none;
  display: block;
  font-size: 32px;
  font-weight: 200;
  margin: 0 0 40px;
  padding: 0
}

.menu button.close {
  position: absolute;
  top: 50px;
  right: 0;
  cursor: pointer;
  margin: 40px 20px;
}

.menu-content {
  background-color: #fff;
  opacity: 0.8;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 400px;
}

@media (max-width: 1100px) {

  .menu-content {
    background: transparent
  }
}

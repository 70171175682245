:root {

  /* V2 variables */
}
.cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  margin: 100px 0 40px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
@media (max-width: 1100px) {
  .cards {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .cards > div {
    height: auto !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.card--investment {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  margin: 0 10px 10px 0;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 200px;
          flex: 0 0 200px;
  width: 200px;
  text-decoration: none;
  color: #4a4a4a
}
.card--investment img {
  -ms-flex-item-align: center;
      align-self: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.card--investment p {
  font-size: 18px;
}
.card--investment a {
  color: #2ad2c9;
  text-decoration: none;
  font-weight: 600;
  font-size: 24px;
  display: block;
  margin-bottom: 10px;
  line-height: 1;
}
.card--investment a:hover {
  color: #00b388;
}
@media (max-width: 500px) {
  .card--investment {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    -ms-flex-item-align: center;
        align-self: center;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    width: 100%;
  }
}
.card--investment a {
  color: #ff8d6d;
  font-size: 18px;
}
.card--investment a:hover {
  color: #ff7a39;
}
.card--investment p {
  margin: 0;
  font-size: 24px;
}
.year {
  font-size: 70px;
  weight: 100;
}
.card--article {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  margin: 0 10px 10px 0;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 200px;
          flex: 0 0 200px;
  width: 200px;
  text-decoration: none;
  color: #4a4a4a
}
.card--article img {
  -ms-flex-item-align: center;
      align-self: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.card--article p {
  font-size: 18px;
}
.card--article a {
  color: #2ad2c9;
  text-decoration: none;
  font-weight: 600;
  font-size: 24px;
  display: block;
  margin-bottom: 10px;
  line-height: 1;
}
.card--article a:hover {
  color: #00b388;
}
@media (max-width: 500px) {
  .card--article {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    -ms-flex-item-align: center;
        align-self: center;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    width: 100%;
  }
}
.card--article {
  padding: 0;
  margin: 1.5%;
  width: 30%;
  -ms-flex-item-align: start;
      align-self: flex-start;
  border-radius: 0;
}
@media (max-width: 500px) {
  .card--article {
    width: 100%;
    margin: 0 0 8% 0;
  }
}
.card--article img {
  width: 100%;
  height: 180px;
  -o-object-fit: cover;
     object-fit: cover;
}
.card--article div {
  padding: 20px;
}
.card--article div h4 {
  margin: 0 0 1rem 0;
  font-size: 18px;
}
.card--article div a {
  line-height: 1.5rem;
  font-size: 22px;
}
.card--article div p {
  font-size: 18px;
}
.card--company {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  margin: 0 10px 10px 0;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 200px;
          flex: 0 0 200px;
  width: 200px;
  text-decoration: none;
  color: #4a4a4a
}
.card--company img {
  -ms-flex-item-align: center;
      align-self: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.card--company p {
  font-size: 18px;
}
.card--company a {
  color: #2ad2c9;
  text-decoration: none;
  font-weight: 600;
  font-size: 24px;
  display: block;
  margin-bottom: 10px;
  line-height: 1;
}
.card--company a:hover {
  color: #00b388;
}
@media (max-width: 500px) {
  .card--company {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    -ms-flex-item-align: center;
        align-self: center;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    width: 100%;
  }
}
.card--company {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}
.card--company h4 {
  font-size: 74px;
  font-weight: 900;
  line-height: 1.1;
  font-size: 26px;
  margin: 10px 0 5px;
}
.card--company img {
  max-height: 100px;
  max-width: 100%;
}
.card--team {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  margin: 0 10px 10px 0;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 200px;
          flex: 0 0 200px;
  width: 200px;
  text-decoration: none;
  color: #4a4a4a
}
.card--team img {
  -ms-flex-item-align: center;
      align-self: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.card--team p {
  font-size: 18px;
}
.card--team a {
  color: #2ad2c9;
  text-decoration: none;
  font-weight: 600;
  font-size: 24px;
  display: block;
  margin-bottom: 10px;
  line-height: 1;
}
.card--team a:hover {
  color: #00b388;
}
@media (max-width: 500px) {
  .card--team {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    -ms-flex-item-align: center;
        align-self: center;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    width: 100%;
  }
}
.card--team {
  background-color: transparent;
  margin: 0 30px 30px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.card--team img {
  border-radius: 10px;
  width: 100%;
}
.card--team h4 {
  font-size: 74px;
  font-weight: 900;
  line-height: 1.1;
  font-size: 26px;
  margin: 10px 0 5px;
}
.card--placeholder {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  margin: 0 10px 10px 0;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 200px;
          flex: 0 0 200px;
  width: 200px;
  text-decoration: none;
  color: #4a4a4a;
}
.card--placeholder img {
  -ms-flex-item-align: center;
      align-self: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.card--placeholder p {
  font-size: 18px;
}
.card--placeholder a {
  color: #2ad2c9;
  text-decoration: none;
  font-weight: 600;
  font-size: 24px;
  display: block;
  margin-bottom: 10px;
  line-height: 1;
}
.card--placeholder a:hover {
  color: #00b388;
}
@media (max-width: 500px) {
  .card--placeholder {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    -ms-flex-item-align: center;
        align-self: center;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    width: 100%;
  }
}
.card--placeholder {
  padding: 20px;
  background-color: transparent;
}

:root {

  /* V2 variables */
}
.cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  margin: 100px 0 40px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
@media (max-width: 1100px) {
  .cards {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .cards > div {
    height: auto !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.card--investment {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  margin: 0 10px 10px 0;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 200px;
          flex: 0 0 200px;
  width: 200px;
  text-decoration: none;
  color: #4a4a4a
}
.card--investment img {
  -ms-flex-item-align: center;
      align-self: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.card--investment p {
  font-size: 18px;
}
.card--investment a {
  color: #2ad2c9;
  text-decoration: none;
  font-weight: 600;
  font-size: 24px;
  display: block;
  margin-bottom: 10px;
  line-height: 1;
}
.card--investment a:hover {
  color: #00b388;
}
@media (max-width: 500px) {
  .card--investment {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    -ms-flex-item-align: center;
        align-self: center;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    width: 100%;
  }
}
.card--investment a {
  color: #ff8d6d;
  font-size: 18px;
}
.card--investment a:hover {
  color: #ff7a39;
}
.card--investment p {
  margin: 0;
  font-size: 24px;
}
.year {
  font-size: 70px;
  weight: 100;
}
.card--article {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  margin: 0 10px 10px 0;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 200px;
          flex: 0 0 200px;
  width: 200px;
  text-decoration: none;
  color: #4a4a4a
}
.card--article img {
  -ms-flex-item-align: center;
      align-self: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.card--article p {
  font-size: 18px;
}
.card--article a {
  color: #2ad2c9;
  text-decoration: none;
  font-weight: 600;
  font-size: 24px;
  display: block;
  margin-bottom: 10px;
  line-height: 1;
}
.card--article a:hover {
  color: #00b388;
}
@media (max-width: 500px) {
  .card--article {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    -ms-flex-item-align: center;
        align-self: center;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    width: 100%;
  }
}
.card--article {
  padding: 0;
  margin: 1.5%;
  width: 30%;
  -ms-flex-item-align: start;
      align-self: flex-start;
  border-radius: 0;
}
@media (max-width: 500px) {
  .card--article {
    width: 100%;
    margin: 0 0 8% 0;
  }
}
.card--article img {
  width: 100%;
  height: 180px;
  -o-object-fit: cover;
     object-fit: cover;
}
.card--article div {
  padding: 20px;
}
.card--article div h4 {
  margin: 0 0 1rem 0;
  font-size: 18px;
}
.card--article div a {
  line-height: 1.5rem;
  font-size: 22px;
}
.card--article div p {
  font-size: 18px;
}
.card--company {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  margin: 0 10px 10px 0;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 200px;
          flex: 0 0 200px;
  width: 200px;
  text-decoration: none;
  color: #4a4a4a
}
.card--company img {
  -ms-flex-item-align: center;
      align-self: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.card--company p {
  font-size: 18px;
}
.card--company a {
  color: #2ad2c9;
  text-decoration: none;
  font-weight: 600;
  font-size: 24px;
  display: block;
  margin-bottom: 10px;
  line-height: 1;
}
.card--company a:hover {
  color: #00b388;
}
@media (max-width: 500px) {
  .card--company {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    -ms-flex-item-align: center;
        align-self: center;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    width: 100%;
  }
}
.card--company {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}
.card--company h4 {
  font-size: 74px;
  font-weight: 900;
  line-height: 1.1;
  font-size: 26px;
  margin: 10px 0 5px;
}
.card--company img {
  max-height: 100px;
  max-width: 100%;
}
.card--team {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  margin: 0 10px 10px 0;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 200px;
          flex: 0 0 200px;
  width: 200px;
  text-decoration: none;
  color: #4a4a4a
}
.card--team img {
  -ms-flex-item-align: center;
      align-self: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.card--team p {
  font-size: 18px;
}
.card--team a {
  color: #2ad2c9;
  text-decoration: none;
  font-weight: 600;
  font-size: 24px;
  display: block;
  margin-bottom: 10px;
  line-height: 1;
}
.card--team a:hover {
  color: #00b388;
}
@media (max-width: 500px) {
  .card--team {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    -ms-flex-item-align: center;
        align-self: center;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    width: 100%;
  }
}
.card--team {
  background-color: transparent;
  margin: 0 30px 30px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.card--team img {
  border-radius: 10px;
  width: 100%;
}
.card--team h4 {
  font-size: 74px;
  font-weight: 900;
  line-height: 1.1;
  font-size: 26px;
  margin: 10px 0 5px;
}
.card--placeholder {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  margin: 0 10px 10px 0;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 200px;
          flex: 0 0 200px;
  width: 200px;
  text-decoration: none;
  color: #4a4a4a;
}
.card--placeholder img {
  -ms-flex-item-align: center;
      align-self: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.card--placeholder p {
  font-size: 18px;
}
.card--placeholder a {
  color: #2ad2c9;
  text-decoration: none;
  font-weight: 600;
  font-size: 24px;
  display: block;
  margin-bottom: 10px;
  line-height: 1;
}
.card--placeholder a:hover {
  color: #00b388;
}
@media (max-width: 500px) {
  .card--placeholder {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    -ms-flex-item-align: center;
        align-self: center;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    width: 100%;
  }
}
.card--placeholder {
  padding: 20px;
  background-color: transparent;
}
:root {

  .button-primary {
  background-color: #2ad2c9;
  padding: 12px 23px;
  color: #4a4a4a;
  text-decoration: none;
  display: block;
  border-radius: 30px;
  -ms-flex-item-align: start;
      align-self: start;
  outline: none
  }

  .button-primary:hover {
    background-color: #00b388;
  }

  .button-primary {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    padding: 8px 24px;
    color: #fff;
    background-color: #01a982;
  }

  .button-primary:hover {
    background-color: #008567;
  }

  .button-secondary {
  background-color: #2ad2c9;
  padding: 12px 23px;
  color: #4a4a4a;
  text-decoration: none;
  display: block;
  border-radius: 30px;
  -ms-flex-item-align: start;
      align-self: start;
  outline: none
  }

  .button-secondary:hover {
    background-color: #00b388;
  }

  .button-secondary {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #444;
    padding: 4px 16px;
    border: 2px solid #01a982;
    background-color: transparent;
  }

  .button-secondary:hover {
    border: 3px solid #008567;
    background-color: transparent;
  }

  .button-nav {
  background-color: #2ad2c9;
  padding: 12px 23px;
  color: #4a4a4a;
  text-decoration: none;
  display: block;
  border-radius: 30px;
  -ms-flex-item-align: start;
      align-self: start;
  outline: none
  }

  .button-nav:hover {
    background-color: #00b388;
  }

  .button-nav {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #fff;
    padding: 6px 18px;
    background-color: #01a982;
  }

  .button-nav:hover {
    background-color: #008567;
  }

  .button-nav2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 6px 18px;
    border-radius: 100px;
    margin: 0;
    -webkit-box-flex: 0;
        -ms-flex: none;
            flex: none;
    color: #373737;
    text-decoration: none;
    cursor: pointer
  }

  .button-nav2.active,
    .button-nav2:hover {
    background-color: rgba(0, 0, 0, 0.03922);
  }

  .button-nav2.btn-loop {
    margin: 0 auto;
    font-weight: 700;
    background-color: #01a982;
    color: #fff;
  }
}
.module {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  min-height: 100vh;
}
@media (max-width: 1100px) {
  .module {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.logo {
  position: fixed;
  top: 50px;
  left: 50px;
  z-index: 1;
}
@media (max-width: 1100px) {
  .logo {
    top: 25px;
    left: 25px;
  }
}
.sidebar {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 26.65%;
          flex: 0 0 26.65%;
  background: #ccc;
  position: relative;
}
@media (max-width: 1100px) {
  .sidebar {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
  }
}
.sidebar span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
  width: 26.65%;
  height: 100vh;
  position: fixed;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 1100px) {
  .sidebar span {
    width: 100%;
    position: relative;
    height: auto;
  }
}
.sidebar__img {
  background-size: cover;
  background-position: center center;
}
.light-centered {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 60px
}
.light-centered h2 {
  font-size: 36px;
  font-weight: 400;
  line-height: 48px;
  font-weight: 500;
  letter-spacing: 5.5pt;
  margin-right: -10px;
  font-size: 48px;
  text-transform: capitalize;
}
.light-centered p {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.2;
}
@media (max-width: 1100px) {
  .light-centered {
    padding: 120px 20px 20px;
    position: relative;
    top: auto;
    bottom: auto;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
  }
}
.light-centered {
  text-align: center;
  color: #fff;
  padding: 20px;
}
.light-centered h2 {
  font-size: 36px;
  font-weight: 400;
  line-height: 48px;
  font-weight: 500;
  margin-right: -10px;
  font-size: 36px;
  letter-spacing: normal;
  text-transform: capitalize;
}
.light-centered p {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}
@media (max-width: 1100px) {
  .light-centered {
    padding: 120px 20px 20px;
  }
}
.light-left {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 60px
}
.light-left h2 {
  font-size: 36px;
  font-weight: 400;
  line-height: 48px;
  font-weight: 500;
  letter-spacing: 5.5pt;
  margin-right: -10px;
  font-size: 48px;
  text-transform: capitalize;
}
.light-left p {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.2;
}
@media (max-width: 1100px) {
  .light-left {
    padding: 120px 20px 20px;
    position: relative;
    top: auto;
    bottom: auto;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
  }
}
.light-left {
  text-align: left;
  color: #fff;
}
.light-left h2 {
  font-size: 36px;
  font-weight: 500;
  line-height: 64px;
  margin-right: -10px;
  letter-spacing: normal;
  text-transform: capitalize;
  text-align: left;
  line-height: normal;
}
.light-left p {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
@media (max-width: 1100px) {
  .light-left {
    text-align: center;
  }
}
.dark-left {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 60px
}
.dark-left h2 {
  font-size: 36px;
  font-weight: 400;
  line-height: 48px;
  font-weight: 500;
  letter-spacing: 5.5pt;
  margin-right: -10px;
  font-size: 48px;
  text-transform: capitalize;
}
.dark-left p {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.2;
}
@media (max-width: 1100px) {
  .dark-left {
    padding: 120px 20px 20px;
    position: relative;
    top: auto;
    bottom: auto;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
  }
}
.dark-left {
  color: #444;
  background-color: #fff;
}
.dark-left h2 {
  font-size: 36px;
  font-weight: 500;
  line-height: 64px;
  margin-right: -10px;
  letter-spacing: normal;
  text-transform: capitalize;
  text-align: left;
  line-height: normal;
}
.dark-left p {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.menu {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 60px;
}
.menu h2 {
  font-size: 36px;
  font-weight: 400;
  line-height: 48px;
  font-weight: 500;
  letter-spacing: 5.5pt;
  margin-right: -10px;
  font-size: 48px;
  text-transform: capitalize;
}
.menu p {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.2;
}
@media (max-width: 1100px) {
  .menu {
    padding: 120px 20px 20px;
    position: relative;
    top: auto;
    bottom: auto;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
  }
}
.menu {
  text-align: left;
  color: #fff;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding: 140px 0 0 50px;
  height: 100vh;
}
.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding-top: 15px;
  margin: 100px 96px 0;
  -ms-flex-preferred-size: calc(100vw - 26.65%);
      flex-basis: calc(100vw - 26.65%);
}
@media (max-width: 1100px) {
  .content {
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    margin: 20px;
  }
}
.content p {
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
}
.content h4,
  .content blockquote {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  color: #777;
  font-weight: normal;
  margin-left: 0;
  margin-right: 0;
}
.content a::not(.button) {
  color: #2ad2c9;
  text-decoration: none;
}
.content a::not(.button):hover {
  color: #00b388;
}
.header {
  font-size: 74px;
  font-weight: 900;
  line-height: 1.1;
  margin: 0;
}
.portfolio-page {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 180px 96px;
  gap: 48px;
}
@media (max-width: 1100px) {
  .portfolio-page {
    height: auto;
    overflow: hidden;
    padding: 96px;
  }
}
@media (max-width: 500px) {
  .portfolio-page {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.investment__header-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 48px
}
.investment__header-container h2 {
  font-size: 62px;
}
.investment__header-container p {
  color: #6f6f6f;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.investment__header-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start
}
.investment__header-content img {
  max-height: 150px;
}
.sub-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 24px;
  font-size: 18px;
}
.link {
  font-size: 18px;
  color: #01a982;
  text-decoration: none;
  cursor: pointer
}
.link:hover {
  color: #00b388;
}
.vertical-rule {
  border-left: 1px solid rgba(0, 0, 0, .12);
}
.card--team {
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
  background: #fff;
  padding: 0;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  text-align: left;
  margin: 0 10px 10px 0
}
.card--team img {
  width: 100%;
  height: auto;
  border-radius: 10px 10px 0 0;
}
.card--team div {
  -ms-flex-item-align: stretch;
      align-self: stretch;
  padding: 30px;
}
.card--team p {
  margin: 0;
}
.info-container {
  display: grid;
  row-gap: 24px;
  -webkit-column-gap: 24px;
          column-gap: 24px;
  grid-template-columns: -webkit-max-content;
  grid-template-columns: max-content;
  grid-template-areas: 'founders history';
}
@media (max-width: 1330px) {
  .info-container {
    grid-template-areas:
      'founders'
      'history';
  }
}
@media (max-width: 500px) {
  .info-container {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.founder-container {
  grid-area: 'founders';
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 24px;
}
.history-container {
  grid-area: 'history';
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  gap: 24px;
}
.news-header {
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.article-container {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 24px;
}
@media (max-width: 500px) {
  .article-container {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.investment-content {
  margin: 180px 96px 0;
}
@media (max-width: 1100px) {
  .investment-content {
    margin-top: 0;
  }
}

:root {

  /* V2 variables */
}

.footer {
  z-index: 1;
  padding: 50px 0;
  color: #777;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media (max-width: 1300px) {

  .footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}

.footer a {
  text-decoration: none;
  color: #777;
}

.footer-home {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  padding: 0;
  margin-top: 20px;
}

@media (max-width: 1380px) {

  .footer-home {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
  }
}

.footer-home p {
  font-size: 18px !important;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  margin: 0;
}

@media (min-width: 1001px) and (max-height: 630px) {

  .footer-home p {
    font-size: 2.8vh !important;
    line-height: 1.2;
  }
}

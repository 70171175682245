:root {

  /* V2 variables */
}
:root {

  .button-primary {
  background-color: #2ad2c9;
  padding: 12px 23px;
  color: #4a4a4a;
  text-decoration: none;
  display: block;
  border-radius: 30px;
  -ms-flex-item-align: start;
      align-self: start;
  outline: none
  }

  .button-primary:hover {
    background-color: #00b388;
  }

  .button-primary {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    padding: 8px 24px;
    color: #fff;
    background-color: #01a982
  }

  .button-primary:hover {
    background-color: #008567;
  }

  .button-secondary {
  background-color: #2ad2c9;
  padding: 12px 23px;
  color: #4a4a4a;
  text-decoration: none;
  display: block;
  border-radius: 30px;
  -ms-flex-item-align: start;
      align-self: start;
  outline: none
  }

  .button-secondary:hover {
    background-color: #00b388;
  }

  .button-secondary {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #444;
    padding: 4px 16px;
    border: 2px solid #01a982;
    background-color: transparent
  }

  .button-secondary:hover {
    border: 3px solid #008567;
    background-color: transparent;
  }

  .button-nav {
  background-color: #2ad2c9;
  padding: 12px 23px;
  color: #4a4a4a;
  text-decoration: none;
  display: block;
  border-radius: 30px;
  -ms-flex-item-align: start;
      align-self: start;
  outline: none
  }

  .button-nav:hover {
    background-color: #00b388;
  }

  .button-nav {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #fff;
    padding: 6px 18px;
    background-color: #01a982
  }

  .button-nav:hover {
    background-color: #008567;
  }

  .button-nav2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 6px 18px;
    border-radius: 100px;
    margin: 0;
    -webkit-box-flex: 0;
        -ms-flex: none;
            flex: none;
    color: #373737;
    text-decoration: none;
    cursor: pointer
  }

  .button-nav2.active,
    .button-nav2:hover {
    background-color: rgba(0, 0, 0, 0.03922);
  }

  .button-nav2.btn-loop {
    margin: 0 auto;
    font-weight: 700;
    background-color: #01a982;
    color: #fff;
  }
}
.navigation {
  position: fixed;
  z-index: 2;
  padding: 75px 0;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 73.35%;
  margin-left: 26.65%;
  background-color: #f8f8f8;
  opacity: 0.95;
}
@media (max-width: 1100px) {
  .navigation {
    width: 100vw;
    padding: 40px 0 28px;
    left: 0;
    position: -webkit-sticky;
    position: sticky;
    margin-top: 60px;
    margin-left: 0
  }
}
@media (max-width: 1100px) {
  .navigation a.item {
    display: none
  }
}
.navigation button.item {
  display: none;
  -ms-flex-item-align: end;
      align-self: flex-end;
}
@media (max-width: 1100px) {
  .navigation button.item {
    display: block
  }
}
.navigation-wrapper {
  position: absolute;
  top: 66px;
  width: 100%;
}
@media (max-width: 1100px) {
  .navigation-wrapper {
    display: none
  }
}
.navigationV2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  height: 84px;
  right: 0px;
  top: 0px;
}
.navigationV2-section-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0px;
  gap: 12px;

  margin: 0 auto;
  height: 36px;

  -webkit-box-flex: 0;

      -ms-flex: none;

          flex: none;
  -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0;
  -ms-flex-positive: 0;
      flex-grow: 0;
}

.modal__container {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, .6);
  z-index: 10;
}

.modal__content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 10;
}

:root {

  /* V2 variables */
}
.testimonial-container {
  position: relative;
}
.slider-container {
  margin-bottom: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  overflow: hidden;
}
.slide {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  gap: 24px;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}
.slide:nth-child(1) {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}
.testimonial-company {
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center
}
.testimonial-company img {
  max-height: 100%;
  max-width: 100%;
}
.testimonial-text {
  font-size: 24px;
  color: #6f6f6f;
  line-height: 1.75;
  min-height: 250px;
}
.testimonial-title-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 8px;
}
.testimonial-name {
  font-size: 18px;
  color: #6f6f6f;
}
.testimonial-title {
  font-size: 18px;
  color: #01a982;
  font-style: italic;
}
.testimonial-nav {
  position: absolute;
  margin-bottom: 58px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 5px;
  bottom: 0;
  right: 0;
}
.testimonial-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  gap: 10px;
}
.testimonial-dot {
  cursor: pointer;
  border: 1px solid #6f6f6f;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.selected-testimonial-dot {
  cursor: pointer;
  background-color: #01a982;
  width: 9px;
  height: 9px;
  border-radius: 50%;
}
.arrow {
  height: 50px
}
.arrow:hover {
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="table" tableValues="0.52 0.48" /><feFuncG type="table" tableValues="0.52 0.48" /><feFuncB type="table" tableValues="0.52 0.48" /></feComponentTransfer><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.55082 0.56906 0.13985999999999998 0 0 0.25826 0.7676400000000001 0.12432000000000001 0 0 0.20128000000000001 0.39516 0.35694000000000004 0 0 0 0 0 1 0" /><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="32.36195 -28.49275 -2.8691999999999998 0 0 -8.488050000000001 12.765749999999999 -2.8691999999999998 0 0 -8.488050000000001 -28.49275 37.98080000000001 0 0 0 0 0 1 0" /><feColorMatrix type="hueRotate" color-interpolation-filters="sRGB" values="138" /><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="0.94" /><feFuncG type="linear" slope="0.94" /><feFuncB type="linear" slope="0.94" /></feComponentTransfer><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="0.99" intercept="0.0050000000000000044" /><feFuncG type="linear" slope="0.99" intercept="0.0050000000000000044" /><feFuncB type="linear" slope="0.99" intercept="0.0050000000000000044" /></feComponentTransfer></filter></svg>#filter');
  -webkit-filter: invert(52%) sepia(74%) saturate(4085%) hue-rotate(138deg) brightness(94%) contrast(99%);
          filter: invert(52%) sepia(74%) saturate(4085%) hue-rotate(138deg) brightness(94%) contrast(99%);
}
.nav-arrow {
  margin-right: 0px;
  padding: 0px;
}

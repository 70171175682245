:root {

  /* V2 variables */
}
@-webkit-keyframes open {
  from {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes open {
  from {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
.newsletter-signup__container {
  border: 1px solid rgba(0, 0, 0, .6);
  background-color: #fff;
  padding: 2rem;
  max-width: 576px;
  position: relative;
  -webkit-animation: open 0.35s ease-out;
          animation: open 0.35s ease-out;
}
.newsletter-signup__logo {
  max-width: 275px;
  height: auto;
  position: absolute;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  left: 50%;
  top: 0;
}
.newsletter-signup__header-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column
}
.newsletter-signup__header-container h1 {
  color: #2ad2c9;
  text-align: center;
  margin: 0.25rem 0;
}
.newsletter-signup__header-container h4 {
  color: #000;
  margin: 0.25rem 0;
}
.newsletter-signup__form-names {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem
}
.newsletter-signup__form-names input {
  width: 50%;
}
.newsletter-signup__form-submit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%
}
.newsletter-signup__form-submit input {
  width: 75%;
}
.newsletter-signup__form-submit button {
  width: 25%;
}
.newsletter-signup__form-input:first-child {
  margin-right: 0.75rem;
}
.newsletter-signup__form-input:last-child {
  margin-left: 0.75rem;
}
.newsletter-signup__form-button {
  color: #fff;
  background-color: #2ad2c9;
  margin: 0;
}
.newsletter-signup__form-button:hover {
  color: #2ad2c9;
  background-color: #fff;
  border: 1px solid #2ad2c9;
}
.newsletter-signup__form-button--error {
  color: #fff;
  background-color: #ed4337;
  margin: 0;
}
.newsletter-signup__form-button--error:hover {
  color: #fff;
}
.newsletter-signup__form-input {
  border-radius: none;
  border: 2px solid #d8d8d8;
  padding: 0.25rem 0.5rem;
  font-size: 1.25rem;
}
.newsletter-signup__form-input::-webkit-input-placeholder {
  color: #000;
  opacity: 1;
}
.newsletter-signup__form-input::-ms-input-placeholder {
  color: #000;
  opacity: 1;
}
.newsletter-signup__form-input::placeholder {
  color: #000;
  opacity: 1;
}
.newsletter-signup__form-input:-ms-input-placeholder {
  color: #000;
}
.newsletter-signup__form-input::-ms-input-placeholder {
  color: #000;
}
.newsletter-signup__error-container {
  height: 2.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center
}
.newsletter-signup__error-container p {
  color: #ed4337;
  font-size: 0.9rem;
  margin: 0;
}
.newsletter-signup__copy {
  font-size: 0.75rem
}
.newsletter-signup__copy a {
  color: #2ad2c9;
}
@media (max-width: 768px) {
  .newsletter-signup__form-input:first-child {
    margin-right: 0;
  }

  .newsletter-signup__form-input:last-child {
    margin-left: 0;
  }

  .newsletter-signup__logo {
    position: relative;
    top: 15px;
  }

  .newsletter-signup__form-names {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 0
  }

  .newsletter-signup__form-names input {
    width: 100%;
    margin-bottom: 1rem;
  }

  .newsletter-signup__form-submit {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 0
  }

  .newsletter-signup__form-submit input {
    width: 100%;
    margin-bottom: 1rem;
  }

  .newsletter-signup__form-submit button {
    width: 100%;
  }
}

.header {
  font-size: 74px;
  font-weight: 900;
  line-height: 1.1;
  text-align: center;
}

.description {
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
}

.contribute {
  font-size: 11px;
  font-weight: 400;
  line-height: 24px;
}

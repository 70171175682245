:root {

  /* V2 variables */
}

.filters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  padding: 30px 0;
  position: fixed;
  background: #f8f8f8;
  opacity: 0.95;
  width: 60%;
  top: 150px;
  z-index: 1;
  gap: 6px
}

.filters.news-filter {
  top: 228px;
  gap: 12px;
}

@media (max-width: 1100px) {

  .filters.news-filter {
    top: 0
  }
}

@media (max-width: 1100px) {

  .filters {
    position: relative;
    width: 100%;
    top: 0;
    min-width: 100%;
    z-index: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}

@media (max-width: 1100px) and (max-width: 1100px) {

  .filters {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center
  }
}
